<template>
    <div>
        <a-row :gutter="8">
            <a-col :span="showWaterfactoryList ? 18 : 24">
                <a-card :title="title" :body-style="{padding: '5px 5px'}">
                    <div  slot="extra">
                      <span  style="font-size:16px;">监测点数量：<b style="color:#3399ff;font-size:18px;">{{videoList.length}}</b>{{"\xa0\xa0\xa0\xa0\xa0"}}设备数量：<b style="color:#3399ff;font-size:18px;">{{sumData}}</b>{{"\xa0\xa0\xa0\xa0\xa0\xa0\xa0"}}</span>
                      <a-radio-group default-value="a" button-style="solid" @change="toShowMapOrList">
                        <a-radio-button value="a">
                          地图模式
                        </a-radio-button>
                        <a-radio-button value="b">
                          卡片模式
                        </a-radio-button>
                      </a-radio-group>
                    </div>
                    <div v-show="showMapOrList" style="height: calc(100vh - 210px);">
                          <my-map  ref="myMap" v-if="loading" :adapter="adapter" :zoom="12" :center="convert([ 105.312515, 37.450750 ])" height="100%" projection="EPSG:3857">

                            <my-map-drawer width="300px" height="100px" placement="left" collapsed close-on-click-outside >
                              <my-map-layers :layers="layers" :zIndex="1"></my-map-layers>
                            </my-map-drawer>
                            <!--<my-map-placement placement="left-top"  :margin="[100,0,0,10]">
                               <a-radio-group  v-model="showArea" button-style="solid"  @change="showAreaChange">
                                  <a-radio-button value="a">
                                    显示地名
                                  </a-radio-button>
                                  <a-radio-button value="b">
                                    不显示地名
                                  </a-radio-button>
                                </a-radio-group>
                            </my-map-placement>-->
                            <my-map-pointer placement="right-bottom"></my-map-pointer>
                            <my-map-zoom theme="light" placement="right-top"></my-map-zoom>
                            <my-map-geo :json="geo"  :zIndex="2"  @ready="geoReadyHandle"></my-map-geo>
                            <my-map-scale ></my-map-scale>
                            <my-map-overview :collapsed="true"></my-map-overview>
                            <div v-for="(item) in videoList" :key="item.id">
                                <my-map-html :position="item.coordinate" @click="handleClick(item)" positioning="bottom-center" :offset="[0,-25]">
                                    <div class="map-html-div" style="">{{item.dataName}}({{item.num}})</div>
                                </my-map-html>
                              <my-map-marker :coordinate="item.coordinate" :zIndex="2" :src="src" :scale="0.22"  @click="handleClick(item)">
                              </my-map-marker>
                              <!--<my-map-popup :position="item.coordinate2" style="background-color: rgba(45,140,240,0.8);color: #fff;border-radius:8px;text-align:center;width:auto;" :closable="false"  :showArrow="false">{{item.dataName}}({{item.num}}) <br>-->
                              <!--</my-map-popup>-->
                            </div>
                          </my-map>
                    </div>
                    <div v-show="!showMapOrList" style="height: calc(100vh - 210px);background-color:  rgb(240,242,245)">
                      <a-list
                          rowKey="id"
                          :grid="{gutter: 10, lg: 4, md: 3, sm: 2, xs: 1,xl: 5,xxl : 6}"
                          :dataSource="videoList"
                          style="padding:12px 12px 12px 12px;"
                        >
                          <a-list-item slot="renderItem" slot-scope="item" @click="handleClick(item)">
                            <template>
                              <a-card :hoverable="true">
                                <img slot="cover" alt="example" :src="video2"  style="height:170px;"/>
                                <template class="ant-card-actions" slot="actions" style="text-align:center;margin: auto ;">
                                  <a>{{item.dataName}}</a>
                                  <a style="color:#3399ff;"><b style="font-size:20px;">{{item.num}}</b>处监控</a>
                                </template>
                              </a-card>
                            </template>
                          </a-list-item>
                        </a-list>
                    </div>
                </a-card>
            </a-col>
            <a-col v-if="showWaterfactoryList" :span="6">
                <a-card title="区域列表">
                    <a-tree
                            style="height: calc(100vh - 248px);"
                            :show-line="true"
                            :tree-data="treeData"
                            :replace-fields="replaceFields"
                            :defaultExpandAll="true"
                            @select="onSelect"
                            @check="onCheck"
                            v-if="treeData.length > 0"
                    />
                </a-card>
            </a-col>
        </a-row>
        <a-modal
          title="查看视频 - 双击视频全屏查看"
          :visible="visible"
          width="1100px"
          @ok="handleOk"
          @cancel="handleCancel"
        >
          <div>
            <a-row :gutter="8">
                <a-col :span="16">
                    <a-card :bodyStyle="{padding:'0'}">
                        <div style="height: 400px;">
                          <iframe
                            :src="videoUrl"
                            v-if="showVideo"
                            width="100%"
                            height="100%"
                            id="ysOpenDevice"
                            allowfullscreen
                            frameborder="no"
                          >
                          </iframe>
                        </div>
                    </a-card>
                    <a-card>
                        <div style="height: 30px;text-align:center;">
                          <a-radio-group v-if="videoLists.length>0" :default-value="videoLists[0].id" button-style="solid" >
                            <a-radio-button :value="item.id" v-for="(item) in videoLists" :key="item.id" @click="changeUrl(item)">
                              {{item.name}}
                            </a-radio-button>
                          </a-radio-group>
                        </div>
                    </a-card>
                </a-col>
                <a-col :span="8">
                    <a-card title="设备信息">
                        <div style="height: 380px;">
                          <a-form-model :model="form" :label-col="labelCol" :wrapper-col="wrapperCol">
                            <a-form-model-item label="设备名称">
                              {{form.name}}
                            </a-form-model-item>
                            <a-form-model-item label="设备品牌">
                              {{form.brand}}
                            </a-form-model-item>
                            <a-form-model-item label="设备型号">
                              {{form.model}}
                            </a-form-model-item>
                            <a-form-model-item label="SN">
                              {{form.sn}}
                            </a-form-model-item>
                          </a-form-model>
                        </div>
                    </a-card>
                </a-col>
            </a-row>
          </div>
        </a-modal>
    </div>
</template>

<script>
  import { fromLonLat } from "ol/proj";
  import Point from 'ol/geom/Point';
  import {Icon, Stroke, Style,Circle,Fill} from 'ol/style';
  import './monitor_pub.less'
  import MyMapPointer from '@/components/map/MyMapPointers'
  import {MyMap,MyMapGeo,MyMapLayers,MyMapPlacement,MyMapScale,MyMapOverview,MyMapZoom,MyMapScatter,MyMapLink,MyMapPopup,MyMapMarker,MyMapImage,MyMapHtml,MyMapDrawer} from '$ui/map'
  import Image from '@/assets/Image.png'
  import Terrain from '@/assets/terrain.png'
  import TDT from '$ui/map/sources/preview/TDT.png'
  import tianjin from '$ui/charts/geo/province/tianjin.json'
  import henan from '@/assets/henan5.json'
  import TileLayer from 'ol/layer/Tile'
  import XYZ from 'ol/source/XYZ'
  import pin from '$ui/map/sources/pin/red.png'
  import video from '@/assets/video.png'
  import video2 from '@/assets/video3.png'
  import parseStyle from '$ui/map/utils/style'
  import arrowPng from '$ui/map/sources/arrows/arrow.svg'

  import { devicePage,deviceTypeAll,zhibiaoAll,monitorAll,groupAll,waterfactoryAll,getAreaParam,waterfactoryTree,findGroupVideoByWfid,findVideoByGroupid } from "@/api/monitor/index";


  const setStyle = function (bg = 'rgba(0,0,0,0)') {
    return parseStyle({
      stroke: {width: 10, color: bg}
    })
  }
  /**
   * 内部箭头样式
   * 用于每次渲染集合体的时候，OL 均会调用此函数以获得自定义样式
   * @param feature
   * @param res
   * @returns {undefined[]}
   */
  const arrowStyles =  ((feature, res)=> {
      const featureData = feature.getProperties()
      let geometry = feature.getGeometry().getLineString();
      const ls = featureData.ls
      /*let newData = this.leakData.filter(item => item.fromid==featureData.MidFrom&&item.toid==featureData.MidTo)
      let colorIndex=Math.floor(newData[0].leakPercent/5)
      let color=colorData[colorIndex>4?4:colorIndex]*/

      const value = Math.ceil(225 * parseInt(ls) / 5)
      let styles =  [];
      let color='green'
      if(ls=='0'){
        color=`#0000ff`
        styles =  [
            new Style({
                stroke: new Stroke({
                    color: color,
                    width: 10,
                })
            })
        ];
        return styles;
      }else{
        color=`rgba(225, ${225 - value}, 0, 0.9)`
        styles =  [
            new Style({
                stroke: new Stroke({
                    color: color,
                    width: 10,
                })
            })
        ];
      }
      const length = geometry.getLength();

      var radio = (50 * res) / length;
      var dradio = 1;//投影坐标系，如3857等，在EPSG:4326下可以设置dradio=10000
      for (var i = 0; i <= 1; i += radio) {
        var arrowLocation = geometry.getCoordinateAt(i);
        geometry.forEachSegment(function (end,start ) {
          if (start[0] == end[0] || start[1] == end[1]) return;
          var dx1 = end[0] - arrowLocation[0];
          var dy1 = end[1] - arrowLocation[1];
          var dx2 = arrowLocation[0] - start[0];
          var dy2 = arrowLocation[1] - start[1];
          if (dx1 != dx2 && dy1 != dy2) {
            if (Math.abs(dradio * dx1 * dy2 - dradio * dx2 * dy1) < 0.001) {
                var dx = end[0] - start[0];
                var dy = end[1] - start[1];
                var rotation = Math.atan2(dy, dx);
                styles.push(new Style({
                  geometry: new Point(arrowLocation),
                  image: new Icon({
                    src: arrowPng,
                    anchor: [0.75, 0.5],
                    rotateWithView: false,
                    rotation: -rotation + Math.PI ,
                    scale: .06
                  })
                }));
             }
        }
      });
    }
      return styles;
  })
  const data = [
    'Racing car sprays burning fuel into crowd.',
    'Japanese princess to wed commoner.',
    'Australian walks 100km after outback crash.',
    'Man charged over missing wedding girl.',
  ];

  export default {
    name: "monitoring",
    components: {
      MyMap,
      MyMapGeo,
      MyMapPointer,
      MyMapLayers,
      MyMapPlacement,
      MyMapScale,
      MyMapOverview,
      MyMapZoom,
      MyMapScatter,
      MyMapLink,
      MyMapPopup,
      MyMapMarker,
      MyMapImage,
      MyMapHtml,
      MyMapDrawer
    },
    data (){
      return {
        showWaterfactoryList: false,
        video2,
        title: ' ',
        treeData:[],
        replaceFields: {
          children: 'children',
          title: 'name',
          key: 'id'
        },
        adapter:({LayerGroup})=>{
          let settings=['vec_c', 'vec_w', 'cva_w', 'ibo_w']
          return new LayerGroup({
            layers: settings.map(n =>
                this.createTdtLayer(n)
            )
          })
        },
        layers: [
          {
            title: '交通图',
            adapter: ({LayerGroup})=>{
              let settings=['vec_c', 'vec_w', 'cva_w', 'ibo_w']
              return new LayerGroup({
                layers: settings.map(n =>
                    this.createTdtLayer(n)
                )
              })
            },
            preview: TDT
          },
          {
            title: '影像图',
            adapter: ({LayerGroup})=>{
              let settings=['img_w', 'cia_w']
              return new LayerGroup({
                layers: settings.map(n =>
                    this.createTdtLayer(n)
                )
              })
            },
            preview: Image
          },
          {
            title: '地形图',
            adapter: ({LayerGroup})=>{
              let settings=['ter_w', 'cta_w']
              return new LayerGroup({
                layers: settings.map(n =>
                    this.createTdtLayer(n)
                )
              })
            },
            preview: Terrain
          }
        ],
        geo: henan,
        showArea: 'b',
        loading:true,
        ripple1: {
            color: 'red', // 默认与 fill 参数一致
            period: 2, //  动画周期，秒数
            scale: 5, // 动画中波纹的最大缩放比
            type: 'fill' // 渲染类型 fill 或 stroke
        },
        ripple2: {
            period: 1, //  动画周期，秒数
            scale: 8, // 动画中波纹的最大缩放比
            strokeColor: 'red',
            strokeWidth: 5, // 边框宽度
            type: 'stroke' // 渲染类型 fill 或 stroke
        },
        monitorList: [],
        markers: null,
        zuitaimarkers: null,
        pin,
        popupOptions:{
          width:'120'
        },
        src: video,
        videoList: [],
        ModalText: '查看视频',
        visible: false,
        videoUrl:'',
        videoUrlList:[],
        videoLists:[],
        labelCol: { span: 6 },
        wrapperCol: { span: 14 },
        form: {
          name: '12331',
          brand: undefined,
          model: undefined,
          sn: undefined,
          param: undefined,
          id: undefined,
          groupid: undefined,
        },
        data,
        showVideo:false,
        showMapOrList:true,
        sumData:0
      }
    },
    created(){
      let areaParam = getAreaParam()
      waterfactoryTree(areaParam).then(r=>{
        this.treeData = [r.data]
        // this.title = this.treeData[0].name
        this.title = '沙坡头区/宣和东台供水自动站'
      })
      monitorAll().then(r=>{
        console.log(r)
        let monitorList = r.data
        let data1 = monitorList.filter(item => item.zutaiid ==null)
        let data2 = monitorList.filter(item => item.zutaiid !=null)

        this.markers =this.createMarkers(data1)
        this.zuitaimarkers =this.createMarkers(data2)
      })
      findGroupVideoByWfid(1).then(r=>{
        let res = r.data
        console.log('this.videoList========='+JSON.stringify(this.createVideoImages(res)))
        let data=this.createVideoImages(res)
        this.videoList=data.markers
        this.sumData=data.num
      })
    },
    mounted(){

    },
    methods:{
      convert(obj){
        return fromLonLat(obj)
      },
      onSelect(selectedKeys, info) {
        console.log('selected', selectedKeys, info);
      },
      onCheck(checkedKeys, info) {
        console.log('onCheck', checkedKeys, info);
      },
      createTdtLayer(t = 'vec_c') {
        return new TileLayer({
          source: new XYZ({
            url: 'http://t{0-7}.tianditu.gov.cn/DataServer?T=' + t + '&x={x}&y={y}&l={z}&tk=8991986746152eb942b797bfe6b2e1f9'
          })
        })
      },
      showAreaChange(val){
        console.log('-----------------------------'+val)
        this.loading=false
        if(val.target.value=='a'){
          this.$nextTick(() => {
            /*
            const map = this.$refs.myMap.map
            const layer = this.$refs.myMap.getLayer()
            if (layer) {
              map.removeLayer(layer)
            }
            if (this.adapter) {
              this.adapter.__MY_LAYER__ = true
              map.addLayer(this.adapter)
            }*/

            this.adapter=({LayerGroup})=>{
              let settings=['img_w', 'cia_w']
              return new LayerGroup({
                layers: settings.map(n =>
                    this.createTdtLayer(n)
                )
              })
            }

            this.layers= [
              {
                title: '影像图',
                adapter: ({LayerGroup})=>{
                  let settings=['img_w', 'cia_w']
                  return new LayerGroup({
                    layers: settings.map(n =>
                        this.createTdtLayer(n)
                    )
                  })
                },
                preview: Image
              },
              {
                title: '交通图',
                adapter: ({LayerGroup})=>{
                  let settings=['vec_c', 'vec_w', 'cva_w', 'ibo_w']
                  return new LayerGroup({
                    layers: settings.map(n =>
                        this.createTdtLayer(n)
                    )
                  })
                },
                preview: TDT
              },
              {
                title: '地形图',
                adapter: ({LayerGroup})=>{
                  let settings=['ter_w', 'cta_w']
                  return new LayerGroup({
                    layers: settings.map(n =>
                        this.createTdtLayer(n)
                    )
                  })
                },
                preview: Terrain
              }
            ]
            this.loading=true
          })
        }else{
          this.$nextTick(() => {
            this.adapter={
                    type: 'XYZ',
                    url:'http://t{0-7}.tianditu.gov.cn/DataServer?T=img_w&x={x}&y={y}&l={z}&tk=8991986746152eb942b797bfe6b2e1f9'
            }

            this.layers= [
              {
                title: '影像图',
                adapter: {
                    type: 'XYZ',
                    url:'http://t{0-7}.tianditu.gov.cn/DataServer?T=img_w&x={x}&y={y}&l={z}&tk=8991986746152eb942b797bfe6b2e1f9'
                },
                preview: Image
              },
              {
                title: '交通图',
                adapter: {
                    type: 'XYZ',
                    url:'http://t{0-7}.tianditu.gov.cn/DataServer?T=vec_w&x={x}&y={y}&l={z}&tk=8991986746152eb942b797bfe6b2e1f9'
                },
                preview: TDT
              },
              {
                title: '地形图',
                adapter: {
                    type: 'XYZ',
                    url:'http://t{0-7}.tianditu.gov.cn/DataServer?T=ter_w&x={x}&y={y}&l={z}&tk=8991986746152eb942b797bfe6b2e1f9'
                },
                preview: Terrain
              }
            ]
            this.loading=true
          })
        }
      },
      toShowMapOrList(val){
        if(val.target.value=='a'){
          this.showMapOrList=true
        }else{
          this.showMapOrList=false
        }
      },
      createMarkers(data) {
        const markers = []
        for (let i = 0; i < data.length; i++) {
          markers.push({
            coordinate: this.convert([data[i].lon,data[i].lat]),
            dataName: data[i].name,
          })
        }
        return markers
      },
      geoReadyHandle(geo) {
        const features = geo.getFeatures()
        features.forEach((feature) => {
          const featureData = feature.getProperties()
          const ls = featureData.ls
          if(ls){
              feature.setStyle(arrowStyles)

            /*
            if(ls=='0'){
              const styleObj = setStyle(`#ff9900`)
              feature.setStyle(styleObj)
            }else{
              const value = Math.ceil(225 * parseInt(ls) / 5)
              const styleObj = setStyle(`rgba(225, ${225 - value}, 0, 0.9)`)
              feature.setStyle(styleObj)
            }*/
          }
        })
      },
      createVideoImages(data) {
        const markersData = {
          markers:[],
          num:0
        }
        for (let i = 0; i < data.length; i++) {
          markersData.markers.push({
            coordinate: this.convert([data[i].lon,data[i].lat]),
            dataName: data[i].name,
            id: data[i].id,
            wfid: data[i].wfid,
            num: data[i].num,
          })
          markersData.num+=data[i].num
        }
        return markersData
      },
      handleClick(e){
        console.log(e)
        findVideoByGroupid(e.id).then(r=>{
          let redata=r.data
          console.log(redata)
          let res = r.data[0]
          this.form= res
          this.videoUrlList=JSON.parse(res.param)
          this.videoUrl=this.videoUrlList[0].URL

          this.videoLists=this.createVideoList(redata)
          console.log(this.videoLists)
          this.visible = true;
          this.showVideo = true;
        })
      },
      createVideoList(data) {
        const markers = []
        for (let i = 0; i < data.length; i++) {
          let url=JSON.parse(data[i].param)
          markers.push({
            id: data[i].id,
            name: data[i].name,
            sn: data[i].sn,
            model: data[i].model,
            brand: data[i].brand,
            param: url[0].URL,
          })
        }
        return markers
      },
      handleCancel(e) {
        console.log('Clicked cancel button');
        this.visible = false;
      },
      handleOk(e) {
        console.log(e);
        this.visible = false;
      },
      changeUrl(val){
        console.log('val',val)
        this.showVideo=false
        this.form=val
        this.videoUrl=val.param
        setTimeout(() => {
          this.showVideo=true
        }, 1000)
      }
    }
  };
</script>

<style scoped>

</style>
